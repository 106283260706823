import * as React from 'react';

import URLS from '../../urls';
import { trackGlobal } from '../../services/tracker';
import { useLocale, useLocalizedDiscourseURL } from '../locale-helpers';
import { trackGoogleAnalyticsEvent, EVENT_CATEGORIES, EVENT_NAMES } from '../../services/ga-event-tracker';

interface SharedLinkProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
}

export const GitHubLink = ({ ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  return (
    <a
      target="_blank"
      href={`${URLS.GITHUB_ROOT}`}
      rel="noopener noreferrer"
      onClick={() => trackGlobal('github', locale)}
      {...props}
    />
  );
};

export const DiscourseLink = ({ ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  const discourseURL = useLocalizedDiscourseURL();
  return (
    <a
      target="blank"
      href={discourseURL}
      onClick={() => trackGlobal('discourse', locale)}
      {...props}
    />
  );
};

export const MatrixLink = ({ ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  return (
    <a
      target="blank"
      href="https://chat.mozilla.org/#/room/#common-voice:mozilla.org"
      onClick={() => trackGlobal('matrix', locale)}
      {...props}
    />
  );
};

export const VoiceittLink = ({ ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  return (
    <a
      target="blank"
      href="https://voiceitt.com/"
      onClick={() => trackGlobal('voiceitt', locale)}
      {...props}
    />
  );
};

export const ContactLink = ({ ...props }: SharedLinkProps) => {
  const handleContactClicked = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.CONTACT_US,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Contact link in the footer'
    );
    window.open(URLS.CONTACT_US, '_blank').focus();
  };

  return (
    <a
      {...props}
      onClick={handleContactClicked}
    />
  );
};
