import * as React from 'react';
import * as Modal from 'react-modal';
import { CloseIcon } from '../ui/icons';

interface ButtonConfig {
  [name: string]: {
    handler: () => any;
    variant: 'primary' | 'secondary' | null;
  };
}

export interface ModalProps {
  buttons?: ButtonConfig;
  children?: React.ReactNode;
  contentClassName?: string;
  innerClassName?: string;
  titleClassName?: string;
  onRequestClose?: (event?: React.MouseEvent | React.KeyboardEvent) => any;
}

export const ModalButtons = (props: any) => (
  <div className="buttons" {...props} />
);

export default ({
  buttons,
  children,
  innerClassName = '',
  contentClassName = '',
  titleClassName = '',
  ...props
}: ModalProps) => (
  <Modal
    isOpen={true}
    contentLabel="modal"
    {...props}
    style={{
      overlay: { background: 'rgba(0, 0, 0, 0.8)' },
      content: {
        padding: 0,
        background: 'transparent',
      },
    }}>
    <div className={'inner ' + innerClassName}>
      <div className={`${titleClassName}`}>
        {props.onRequestClose && (
          <button
            type="button"
            className="close"
            onClick={props.onRequestClose as any}>
            <CloseIcon black />
          </button>
        )}
      </div>

      {
        children && (
          <div className={`${contentClassName}`}>
            {children}
          </div>
        )
      }

      {
        buttons && (
          <ModalButtons>
            {
              Object.keys(buttons).map(label => (
                <button
                  key={label}
                  onClick={buttons[label].handler}
                  className={`btn ${buttons[label].variant ? `btn-${buttons[label].variant}`: ''}`}
                >
                  {label}
                </button>
              ))
            }
          </ModalButtons>
        )
      }
    </div>
  </Modal>
);
