import * as React from 'react';
import { Suspense } from 'react';
import { connect, Provider } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { UserClient } from 'common';
import store from '../stores/root';
import URLS from '../urls';
import {
  isMobileSafari,
  isProduction,
  isStaging,
  replacePathLocale,
  doNotTrack,
  regenerateClientSessionId,
  isCurrentBrowserSupported
} from '../utility';
import {
  createLocalization,
  DEFAULT_LOCALE,
  LOCALES,
  negotiateLocales,
} from '../services/localization';
import API from '../services/api';
import { Locale } from '../stores/locale';
import { Notifications } from '../stores/notifications';
import StateTree from '../stores/tree';
import { Uploads } from '../stores/uploads';
import { User } from '../stores/user';
import Layout from './layout/layout';
import NotificationPill from './notification-pill/notification-pill';
import { Spinner } from './ui/ui';
import {
  localeConnector,
  LocalePropsFromState,
} from './locale-helpers';
import { Flags } from '../stores/flags';
import { ReactLocalization, LocalizationProvider } from '@fluent/react';
const rtlLocales = require('../../../locales/rtl.json');
const SpeakPage = React.lazy(() => import('./pages/contribution/speak/speak'));
const NotSupportedBrowserPage = React.lazy(() => import('./pages/not-supported-browser/not-supported-browser'));
const { install: installGlobalSiteTag } = require('ga-gtag');
import { GOOGLE_ANALYTICS_SITE_TAG, GOOGLE_ANALYTICS_SITE_TAG_CD } from '../constants';
import { isSpeakScreen } from '../utility';

interface PropsFromState {
  api: API;
  account: UserClient;
  notifications: Notifications.State;
  uploads: Uploads.State;
  messageOverwrites: Flags.MessageOverwrites;
}

interface PropsFromDispatch {
  addNotification: typeof Notifications.actions.addBanner;
  removeUpload: typeof Uploads.actions.remove;
  setLocale: typeof Locale.actions.set;
  refreshUser: typeof User.actions.refresh;
}

interface LocalizedPagesProps
  extends PropsFromState,
    PropsFromDispatch,
    LocalePropsFromState,
    RouteComponentProps<any, any, any> {
  userLocales: string[];
}

interface LocalizedPagesState {
  hasScrolled: boolean;
  l10n: ReactLocalization | null;
  uploadPercentage?: number;
}

let LocalizedPage: any = class extends React.Component<
  LocalizedPagesProps,
  LocalizedPagesState
> {
  seenAwardIds: number[] = [];
  state: LocalizedPagesState = {
    hasScrolled: false,
    l10n: null,
    uploadPercentage: null,
  };

  isUploading = false;

  async componentDidMount() {
    await this.prepareBundleGenerator(this.props);
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => this.setState({ hasScrolled: true }), 5000);
    this.props.refreshUser();
    regenerateClientSessionId();
  }

  async UNSAFE_componentWillReceiveProps(nextProps: LocalizedPagesProps) {
    const { account, addNotification, api, uploads, userLocales } = nextProps;

    this.runUploads(uploads).catch(e => console.error(e));

    window.onbeforeunload =
      uploads.length > 0
        ? (e: any) =>
            (e.returnValue =
              'Leaving the page now aborts pending uploads. Are you sure?')
        : undefined;

    if (userLocales.find((locale, i) => locale !== this.props.userLocales[i])) {
      await this.prepareBundleGenerator(nextProps);
    }

    const award = account?.awards
      ? account.awards.find(
          a => !a.notification_seen_at && !this.seenAwardIds.includes(a.id)
        )
      : null;

    if (award) {
      this.seenAwardIds.push(...account.awards.map(a => a.id));
      addNotification(
        `Success, ${award.amount} Clip ${
          award.days_interval == 1 ? 'daily' : 'weekly'
        } goal achieved!`,
        {
          links: [
            {
              children: 'Check out your award!',
              to: URLS.AWARDS,
            },
          ],
        }
      );
      await api.seenAwards('notification');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async runUploads(uploads: Uploads.State) {
    if (this.isUploading) return;
    this.isUploading = true;
    this.setState({ uploadPercentage: 0 });
    for (let i = 0; i < uploads.length; i++) {
      this.setState({ uploadPercentage: (i + 1) / (uploads.length + 1) });
      const upload = uploads[i];
      try {
        await upload();
      } catch (e) {
        console.error('upload error', e);
      }
      this.props.removeUpload(upload);
    }
    this.setState({ uploadPercentage: null });
    this.isUploading = false;

    if (this.props.uploads.length > 0) {
      await this.runUploads(this.props.uploads);
    }
  }

  async prepareBundleGenerator({
    api,
    history,
    userLocales,
  }: LocalizedPagesProps) {
    const [mainLocale] = userLocales;
    const pathname = history.location.pathname;

    if (!LOCALES.includes(mainLocale)) {
      userLocales[0] = DEFAULT_LOCALE;
      this.props.setLocale(DEFAULT_LOCALE);
      history.replace(replacePathLocale(pathname, DEFAULT_LOCALE));
    } else {
      this.props.setLocale(userLocales[0]);
    }

    const { documentElement } = document;
    documentElement.setAttribute('lang', mainLocale);
    documentElement.setAttribute(
      'dir',
      rtlLocales.includes(mainLocale) ? 'rtl' : 'ltr'
    );

    this.setState({
      l10n: await createLocalization(
        api,
        userLocales,
        this.props.messageOverwrites
      ),
    });
  }

  handleScroll = () => {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
    }
  };

  render() {
    const { locale, notifications, toLocaleRoute, account } = this.props;
    const { l10n, uploadPercentage } = this.state;

    if (!l10n) return null;

    const shouldHideProgressContainer = isSpeakScreen(history.location.pathname);

    return (
      <div>
        <div className={shouldHideProgressContainer ? 'upload-progress-container' : ''}>
          <div
            className="upload-progress"
            style={
              uploadPercentage === null
                ? {
                  opacity: 0,
                  width: '100%',
                  animationPlayState: 'paused',
                }
                : {
                  opacity: 1,
                  width: uploadPercentage * 100 + '%',
                  animationPlayState: 'running',
                }
            }
          />
        </div>
        <LocalizationProvider l10n={l10n}>
          <div>
            <div className="notifications">
              {notifications
                .slice()
                .reverse()
                .map(
                  notification =>
                    notification.kind == 'pill' &&
                    notification.type !== 'achievement' && (
                      <NotificationPill
                        key={notification.id}
                        notification={notification}
                      />
                    )
                )}
            </div>

            <Switch>
              {[
                { route: URLS.SPEAK, Component: SpeakPage },
                { route: URLS.NOT_SUPPORTED_BROWSER, Component: NotSupportedBrowserPage }
              ].map(({ route, Component }: any) => (
                <Route
                  key={route}
                  exact
                  path={toLocaleRoute(route)}
                  render={props =>
                    <Component {...props} areClipsUploading={this.isUploading} />
                  }
                />
              ))}
              <Layout />
            </Switch>
          </div>
        </LocalizationProvider>
      </div>
    );
  }
};

LocalizedPage = withRouter(
  localeConnector(
    connect<PropsFromState, PropsFromDispatch>(
      ({ api, flags, notifications, uploads, user }: StateTree) => ({
        account: user.account,
        api,
        messageOverwrites: flags.messageOverwrites,
        notifications,
        uploads,
      }),
      {
        addNotification: Notifications.actions.addBanner,
        removeUpload: Uploads.actions.remove,
        setLocale: Locale.actions.set,
        refreshUser: User.actions.refresh,
      }
    )(LocalizedPage)
  )
);

const history = createBrowserHistory();

class App extends React.Component {
  main: HTMLElement;
  userLocales: string[];

  state: { error: Error; Sentry: any } = { error: null, Sentry: null };

  /**
   * App will handle routing to page controllers.
   */
  constructor(props: any, context: any) {
    super(props, context);

    if (isMobileSafari()) {
      document.body.classList.add('mobile-safari');
    }

    this.userLocales = negotiateLocales(navigator.languages);
  }

  async componentDidMount() {
    const isSupported = isCurrentBrowserSupported();

    if (!isSupported) {
      history.replace(URLS.NOT_SUPPORTED_BROWSER);
      return;
    }

    if (isProduction() && !doNotTrack()) {
      installGlobalSiteTag(GOOGLE_ANALYTICS_SITE_TAG)
    } else if (!isProduction()) {
      // my tracking ID for testing purposes on CD env
      installGlobalSiteTag(GOOGLE_ANALYTICS_SITE_TAG_CD)
    }
  }

  async componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error }, () =>
      history.push(`${this.userLocales[0]}/503`, {
        prevPath: history.location.pathname,
      })
    );
    if (!isProduction() && !isStaging()) return;

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    this.setState({ Sentry });
  }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Provider store={store}>
          <Router history={history}>
            {/* Get rid of locale param in url as requested in VTT-5300 */}
            <Switch>
              <Route
                path="/"
                render={() =>
                  <LocalizedPage
                    userLocales={[DEFAULT_LOCALE]}
                  />
                }
              />
              {Object.values(URLS).map(url => (
                <Route
                  key={url}
                  exact
                  path={url || '/'}
                  render={() => (
                    <Redirect to={`${url}${location.search}`} />
                  )}
                />
              ))}
              <Route
                path="/pt-BR"
                render={({ location }) => (
                  <Redirect to={location.pathname.replace('pt-BR', 'pt')} />
                )}
              />
            </Switch>
          </Router>
        </Provider>
      </Suspense>
    );
  }
}

export default App;
