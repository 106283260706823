import { Localized } from '@fluent/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';
import * as FullStory from '@fullstory/browser';
import { NATIVE_NAMES } from '../../services/localization';
import { getTrackClass } from '../../services/tracker';
import StateTree from '../../stores/tree';
import { User } from '../../stores/user';
import { Locale } from '../../stores/locale';
import URLS from '../../urls';
import { isProduction, isHomePageOpened } from '../../utility';
import { LocaleLink, isContributable, LocaleNavLink } from '../locale-helpers';
import {
  InfoIcon,
  MenuIcon,
  MicIcon,
  TargetIcon,
  ExternalLinkIcon,
} from '../ui/icons';
import { Avatar, LinkButton } from '../ui/ui';
import Content from './content';
import Footer from './footer';
import Logo from './logo';
import Nav from './nav';
import UserMenu from './user-menu';
import cx from 'classnames';
import WelcomeModal from '../welcome-modal/welcome-modal';
import {
  ChallengeTeamToken,
  challengeTeamTokens,
  ChallengeToken,
  challengeTokens,
} from 'common';
import API from '../../services/api';
import NotificationBanner from './../notification-banner/notification-banner';
import { Notifications } from '../../stores/notifications';
import './layout.css';

interface PropsFromState {
  locale: Locale.State;
  user: User.State;
  api: API;
}

interface PropsFromDispatch {
  setLocale: typeof Locale.actions.set;
}

interface LayoutProps
  extends PropsFromState,
    PropsFromDispatch,
    RouteComponentProps<any, any, any> {}

interface LayoutState {
  challengeTeamToken: ChallengeTeamToken;
  challengeToken: ChallengeToken;
  isMenuVisible: boolean;
  hasScrolled: boolean;
  hasScrolledDown: boolean;
  showStagingBanner: boolean;
  showWelcomeModal: boolean;
  featureStorageKey?: string;
  isHomePageOpened: boolean;
}

const SegmentBanner = ({
  locale,
  featureStorageKey,
}: {
  locale: string;
  featureStorageKey: string;
}) => {
  const notification: Notifications.Notification = {
    id: 99,
    kind: 'banner',
    content: (
      <>
        <Localized
          id="target-segment-first-banner"
          vars={{ locale: NATIVE_NAMES[locale] }}
        />
      </>
    ),
    bannerProps: {
      storageKey: featureStorageKey,
      links: [
        {
          to: URLS.SPEAK,
          className: 'cta',
          persistAfterClick: true,
          children: (
            <>
              <TargetIcon />
              <Localized
                key="target-segment-add-voice"
                id="target-segment-add-voice">
                <div />
              </Localized>
            </>
          ),
        },
        {
          href:
            locale === 'es'
              ? URLS.TARGET_SEGMENT_INFO_ES
              : URLS.TARGET_SEGMENT_INFO,
          blank: true,
          persistAfterClick: true,
          className: 'cta external',
          children: (
            <>
              <ExternalLinkIcon />
              <Localized
                key="target-segment-learn-more"
                id="target-segment-learn-more">
                <div />
              </Localized>
            </>
          ),
        },
      ],
    },
  };

  return (
    <NotificationBanner key="target-segment" notification={notification} />
  );
};

class Layout extends React.PureComponent<LayoutProps, LayoutState> {
  private header: HTMLElement;
  private scroller: HTMLElement;
  private installApp: HTMLElement;

  state: LayoutState = {
    challengeTeamToken: undefined,
    challengeToken: undefined,
    isMenuVisible: false,
    hasScrolled: false,
    hasScrolledDown: false,
    showStagingBanner: !isProduction(),
    showWelcomeModal: false,
    featureStorageKey: null,
    isHomePageOpened: false,
  };

  async componentDidMount() {
    const { locale, user } = this.props;
    this.scroller.addEventListener('scroll', this.handleScroll);
    this.visitHash();

    this.props.history.listen(params => {
      this.setState({
        isHomePageOpened: isHomePageOpened(params.pathname),
      });
    });

    const challengeTeamToken = this.getTeamToken();
    const challengeToken = this.getChallengeToken();

    this.setState({
      challengeTeamToken: challengeTeamToken,
      challengeToken: challengeToken,
      showWelcomeModal:
        challengeTeamToken !== undefined && challengeToken !== undefined,
      featureStorageKey: await this.getFeatureKey(locale),
      isHomePageOpened: isHomePageOpened(this.props.history.location.pathname),
    });

    try {
      FullStory.setUserVars({ isLoggedIn: !!user.account });
    } catch (e) {
      // do nothing if FullStory not initialized (see app.tsx)
    }
  }

  componentDidUpdate(nextProps: LayoutProps, _nextState: LayoutState) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ isMenuVisible: false });

      // Immediately scrolling up after page change has no effect.
      setTimeout(() => {
        if (this.scroller) {
          this.scroller.scrollTop = 0;
        }

        if (location.hash) {
          this.visitHash();
        } else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }, 250);
    }
  }

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.handleScroll);
  }

  private visitHash() {
    if (location.hash) {
      setTimeout(() => {
        const node = document.querySelector(location.hash);
        node && node.scrollIntoView();
      }, 100);
    }
  }

  lastScrollTop: number;
  private handleScroll = () => {
    const { scrollTop } = this.scroller;
    this.setState({
      hasScrolled: scrollTop > 0,
      hasScrolledDown: scrollTop > this.lastScrollTop,
    });
    this.lastScrollTop = scrollTop;
  };

  private toggleMenu = () => {
    this.setState({ isMenuVisible: !this.state.isMenuVisible });
  };

  private getChallengeToken = () => {
    return challengeTokens.find(challengeToken =>
      this.props.location.search.includes(`challenge=${challengeToken}`)
    );
  };

  private getTeamToken = () => {
    return challengeTeamTokens.find(challengeTeamToken =>
      this.props.location.search.includes(`team=${challengeTeamToken}`)
    );
  };

  private async getFeatureKey(locale: string) {
    let feature = null;

    if (isContributable(locale)) {
      feature = await this.props.api.getFeatureFlag(
        'singleword_benchmark',
        locale
      );
    }

    return feature ? feature.storageKey : null;
  }

  render() {
    const { locale, location, user } = this.props;
    const {
      challengeTeamToken,
      hasScrolled,
      hasScrolledDown,
      isMenuVisible,
      showStagingBanner,
      showWelcomeModal,
      featureStorageKey,
      isHomePageOpened,
    } = this.state;
    const isBuildingProfile = location.pathname.includes(URLS.PROFILE_INFO);

    const pathParts = location.pathname
      .replace(/(404|503)/g, 'error-page')
      .split('/');

    const className = cx(pathParts[1] ? pathParts.slice(1).join(' ') : 'home', {
      'staging-banner-is-visible': showStagingBanner,
    });

    const alreadyEnrolled =
      this.state.showWelcomeModal && user.account?.enrollment?.challenge;
    const redirectURL = URLS.DASHBOARD + URLS.CHALLENGE;

    return (
      <div id="main" className={className}>
        {alreadyEnrolled && <Redirect to={redirectURL} />}
        {showWelcomeModal && !alreadyEnrolled && (
          <WelcomeModal
            onRequestClose={() => {
              this.setState({ showWelcomeModal: false });
            }}
            teamToken={challengeTeamToken}
          />
        )}
        {featureStorageKey &&
          localStorage.getItem(featureStorageKey) !== 'true' && (
            <SegmentBanner
              locale={locale}
              featureStorageKey={featureStorageKey}
            />
          )}
        <header
          className={`
              ${isHomePageOpened ? 'home-page ' : ''}
              ${
                (!isMenuVisible &&
                  (hasScrolled ? 'active' : '') +
                    ' ' +
                    (hasScrolledDown ? 'hidden' : '')) ||
                ''
              }
            `}
          ref={header => {
            this.header = header as HTMLElement;
          }}>
          <div>
            <Logo reverse />
            <Nav id="main-nav" />
          </div>
          <div>
            {this.renderTallies()}
            {user.account ? (
              <UserMenu />
            ) : isBuildingProfile ? null : (
              <Localized id="login-signup">
                <LinkButton className="login" href="/login" rounded outline />
              </Localized>
            )}
            <button
              id="hamburger-menu"
              onClick={this.toggleMenu}
              className={isMenuVisible ? 'active' : ''}>
              {user.account ? (
                <Avatar url={user.account.avatar_url} />
              ) : (
                <MenuIcon className={isMenuVisible ? 'active' : ''} />
              )}
            </button>
          </div>
        </header>
        <div
          id="scroller"
          ref={div => {
            this.scroller = div as HTMLElement;
          }}>
          <div id="scrollee">
            <Content location={location} />
            <Footer />
          </div>
        </div>
        <div
          id="navigation-modal"
          className={this.state.isMenuVisible ? 'active' : ''}>
          <Nav>
            <div className="user-nav">
              {user.account && (
                <LocaleNavLink
                  className="user-nav-link"
                  to={URLS.PROFILE_INFO}>
                  <InfoIcon />
                  <Localized id="edit-profile">
                    <span />
                  </Localized>
                </LocaleNavLink>
              )}
              {!isBuildingProfile && (
                <>
                  {user.account ? (
                    <Localized id="logout">
                      <LinkButton rounded href="/logout" />
                    </Localized>
                  ) : (
                    <Localized id="login-signup">
                      <LinkButton rounded href="/login" />
                    </Localized>
                  )}
                </>
              )}
            </div>
          </Nav>
        </div>
      </div>
    );
  }

  private renderTallies() {
    const { user } = this.props;
    // VTT-5136 Shir asked to hide signal counter for anonymous users
    const isNotAnonymousUser = Boolean(get(user, 'account.username'));

    return (
      <LocaleLink
        className={[
          'tallies',
          getTrackClass('fs', 'menubar-cta'),
          user.account ? getTrackClass('fs', 'logged-in') : '',
        ].join(' ')}
        to={URLS.SPEAK}>
        {isNotAnonymousUser && (
          <div className="record-tally">
            <MicIcon />
            <div>{user.account.clips_count}</div>
          </div>
        )}
      </LocaleLink>
    );
  }
}

const mapStateToProps = (state: StateTree) => ({
  locale: state.locale,
  user: state.user,
  api: state.api,
});

const mapDispatchToProps = {
  setLocale: Locale.actions.set,
};

export default withRouter(
  connect<PropsFromState, PropsFromDispatch>(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
