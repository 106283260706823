import { UserClient, ClipMetadata } from 'common';
import URLS from './urls';
import {UAParser} from 'ua-parser-js';
import * as _ from 'lodash';
import { SUPPORTED_BROWSERS } from './constants';
import { compare } from 'compare-versions';

const SEARCH_REG_EXP = new RegExp('</?[^>]+(>|$)', 'g');

export function isSpaceBarPressed(event: KeyboardEvent) {
  return event.keyCode === 32
}

/**
 * Generate RFC4122 compliant globally unique identifier.
 */
export function generateGUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function dec2hex(n: number) {
  return ('0' + n.toString(16)).substr(-2);
}

export function generateToken(length = 40) {
  const arr = new Uint8Array(length / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

/**
 * Count the syllables in a string. Completely stolen from:
 * https://codegolf.stackexchange.com/
 *   questions/47322/how-to-count-the-syllables-in-a-word
 */
let re = /[aiouy]+e*|e(?!d$|ly).|[td]ed|le$/gi;
export function countSyllables(text: string): number {
  let matches = text.match(re);
  return matches.length;
}

/**
 * Test whether this is a browser on iOS.
 *
 * NOTE: As of early 2020 this is not reliable on iPad for some privacy-minded
 * browsers, including Safari (!!), Brave, and Firefox Focus.
 */
export function isIOS(): boolean {
  return /iPod|iPhone|iPad|iOS/i.test(window.navigator.userAgent);
}

/**
 * Check whether the browser is mobile Safari on iOS.
 *
 * The logic is collected from answers to this SO question: https://stackoverflow.com/q/3007480
 */
export function isMobileSafari(): boolean {
  return (
    isIOS() &&
    !window.navigator.standalone &&
    /AppleWebKit/i.test(window.navigator.userAgent) &&
    !/Chrome|Focus|CriOS|OPiOS|OPT\/|FxiOS|EdgiOS|mercury/i.test(
      window.navigator.userAgent
    )
  );
}

export function isMobileResolution(): boolean {
  return window.matchMedia('(max-width: 768px)').matches;
}

export function isProduction(): boolean {
  return window.location.origin === URLS.HTTP_ROOT;
}

export function isStaging(): boolean {
  return window.location.origin === URLS.STAGING_ROOT;
}

/**
 * Replaces the locale part of a given path
 */
export function replacePathLocale(pathname: string, locale: string) {
  const pathParts = pathname.split('/');
  pathParts[1] = locale;
  return pathParts.join('/');
}

export function getManageSubscriptionURL(account: UserClient) {
  const firstLanguage = account.locales[0];
  return `https://www.mozilla.org/${
    firstLanguage ? firstLanguage.locale + '/' : ''
  }newsletter/existing/${account.basket_token}`;
}

export const getAudioFormat = (() => {
  const preferredFormat = 'audio/ogg; codecs=opus';
  const audio = document.createElement('audio');
  const format = audio.canPlayType(preferredFormat)
    ? preferredFormat
    : 'audio/wav';
  return function getAudioFormat() {
    return format;
  };
})();

export async function hash(text: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const digest = await window.crypto.subtle.digest('SHA-256', data);

  return [...new Uint8Array(digest)]
    .map(value => value.toString(16).padStart(2, '0'))
    .join('');
}

export function stringContains(haystack: string, needles: string) {
  return (
    haystack.toUpperCase().replace(SEARCH_REG_EXP, '').indexOf(needles) !== -1
  );
}

export function doNotTrack() {
  return navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes';
}

export async function getRecordingDevice() {
  const mediaDevices = await navigator.mediaDevices.enumerateDevices();

  return mediaDevices.find(device => device.deviceId === 'default' && device.kind === 'audioinput');
}

export function getDeviceMetadata(): Partial<ClipMetadata> {
  const parser = new UAParser();
  const parserResult = parser.getResult();

  const metadata = {
    browser_name: _.get(parserResult, 'browser.name'),
    browser_version: _.get(parserResult, 'browser.version'),
    engine_name: _.get(parserResult, 'engine.name'),
    engine_version: _.get(parserResult, 'engine.version'),
    os_name: _.get(parserResult, 'os.name'),
    os_version: _.get(parserResult, 'os.version'),
    device_model: _.get(parserResult, 'device.model'),
    device_type: _.get(parserResult, 'device.type'),
  }

  return _.omitBy(metadata, _.isNil)
}

export function isCurrentBrowserSupported(): boolean {
  const deviceMetadata = getDeviceMetadata();
  const supportedBrowser = SUPPORTED_BROWSERS.find(sb => sb.name === deviceMetadata.browser_name);

  return supportedBrowser && !compare(supportedBrowser.min_version, deviceMetadata.browser_version, '>');
}

export function isHomePageOpened(path = '') {
  const parts = path.split('/');

  return parts.length === 2 || (parts.length > 2 && parts[1] === 'login-success');
}

export const regenerateClientSessionId = () => {
  const sessionId = generateGUID()
  sessionStorage.setItem('clientSessionId', sessionId)

  return sessionId
}

export const getClientSessionId = () => {
  return sessionStorage.getItem('clientSessionId')
}

export const isSpeakScreen = (pathname: string) => {
  return pathname.includes('speak');
}

export const getVoices = (): Promise<SpeechSynthesisVoice[]> => {
  return new Promise(
    function (resolve, reject) {
      let synth = window.speechSynthesis;
      let id: any;

      id = setInterval(() => {
        if (synth.getVoices().length !== 0) {
          resolve(synth.getVoices());
          clearInterval(id);
        }
      }, 10);
    }
  )
}
