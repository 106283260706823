const PROFILE = '/profile';
const DASHBOARD = '/dashboard';
export default Object.freeze({
  ROOT: '/',

  RECORD: '/record', // old, here for redirect purposes
  SPEAK: '/speak',
  NOT_SUPPORTED_BROWSER: '/not_supported_browser',

  PROFILE,
  PROFILE_INFO: PROFILE + '/info',
  PROFILE_AVATAR: PROFILE + '/avatar',
  PROFILE_SETTINGS: PROFILE + '/settings',
  PROFILE_DELETE: PROFILE + '/delete',

  DASHBOARD,
  STATS: '/stats',
  PROFILE_GOALS: PROFILE + '/goals', // old, here for redirect purposes
  GOALS: '/goals',
  AWARDS: '/awards',
  CHALLENGE: '/challenge',

  // DATA: '/data', // old, here for redirect purposes
  // DATASETS: '/datasets',

  FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER: 'https://twitter.com/intent/tweet?text=',
  LINKEDIN: 'https://www.linkedin.com/cws/share?url=',

  APP_LINK: 'https://voiceitt.com/',
  FAQ: 'https://help.voiceitt.com/voiceitt-ensemble-faq',
  PRIVACY: 'https://voiceitt.com/ensemble-privacy-policy',
  TERMS: 'https://voiceitt.com/ensemble-terms-of-service',
  CHALLENGE_TERMS: '/challenge-terms',
  CONTACT_US: 'https://help.voiceitt.com/kb-tickets/new',
  // LANGUAGES: '/languages'

  TARGET_SEGMENT_INFO:
    'https://discourse.mozilla.org/t/help-create-common-voices-first-target-segment/59587',
  TARGET_SEGMENT_INFO_ES:
    'https://discourse.mozilla.org/t/ayuda-a-crear-el-primer-objetivo-segmentado-de-common-voice/60472/',

  HTTP_ROOT: 'https://ensemble.voiceitt.com',
  STAGING_ROOT: 'https://commonvoice.allizom.org',
  GITHUB_ROOT: 'https://github.com/mozilla/common-voice',
  SCHEDULE_MEETING:
    'https://calendar.google.com/calendar/u/0/selfsched?sstoken=UUxXWE1QRFpJTm9nfGRlZmF1bHR8YzY5NjA1MTcxMWE5MDc4MWFmNTZmMDFmMzUwOTEzY2E',
  WEB_APP: 'https://app.voiceitt.com/',
});
