import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import URLS from '../../urls';
import { isContributable, useLocale } from '../locale-helpers';
import DocumentPage from '../pages/document-page';
import { Spinner } from '../ui/ui';
import { LoginFailure, LoginSuccess } from '../pages/login';
const HomePage = React.lazy(() => import('../pages/home/home'));
const ProfileLayoutPage = React.lazy(() => import('../pages/profile/layout'));
const LandingPage = React.lazy(() => import('../pages/landing/landing'));
const ErrorPage = React.lazy(() => import('../pages/error-page/error-page'));

export default function Content({ location }: { location: any }) {
  const [locale, toLocaleRoute] = useLocale();

  return (
    <div id="content">
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={toLocaleRoute(URLS.ROOT)} component={HomePage} />
          <Route
            exact
            path={toLocaleRoute('/new')}
            render={() => <Redirect to={toLocaleRoute(URLS.ROOT)} />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.RECORD)}
            render={() => (
              <Redirect
                to={toLocaleRoute(
                  isContributable(locale) ? URLS.SPEAK : URLS.ROOT
                )}
              />
            )}
          />

          <Route
            exact
            path={toLocaleRoute('/login-failure')}
            component={LoginFailure}
          />
          <Route
            exact
            path={toLocaleRoute('/login-success')}
            component={LoginSuccess}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.PROFILE)}
            render={() => <Redirect to={toLocaleRoute(URLS.PROFILE_INFO)} />}
          />
          <Route
            path={toLocaleRoute(URLS.PROFILE + '/')}
            component={ProfileLayoutPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.PRIVACY)}
            render={() => <DocumentPage key="p" name="privacy" />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.TERMS)}
            render={() => <DocumentPage key="t" name="terms" />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.CHALLENGE_TERMS)}
            render={() => <DocumentPage key="c" name="challenge-terms" />}
          />
          <Route
            exact
            path={toLocaleRoute('/landing/sodedif')}
            component={LandingPage}
          />
          <Route
            path={toLocaleRoute('/404')}
            render={() => (
              <ErrorPage errorCode="404" prevPath={location.state?.prevPath} />
            )}
          />
          <Route
            path={toLocaleRoute('/503')}
            render={() => (
              <ErrorPage errorCode="503" prevPath={location.state?.prevPath} />
            )}
          />
          <Redirect
            push
            to={{
              pathname: toLocaleRoute('/404'),
              state: { prevPath: location.pathname },
            }}
          />
        </Switch>
      </React.Suspense>
    </div>
  );
}
