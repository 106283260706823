import {
  Localized,
  withLocalization,
  WithLocalizationProps,
} from '@fluent/react';
import * as React from 'react';
import { useRef } from 'react';

import { trackSharing } from '../../services/tracker';
import { Notifications } from '../../stores/notifications';
import { FontIcon, FacebookIcon, CopyLinkIcon, TwitterIcon } from '../ui/icons';
import { useLocale } from '../locale-helpers';
import URLS from '../../urls';
import './share-buttons.css';
import { useAction } from '../../hooks/store-hooks';
import { trackGoogleAnalyticsEvent, EVENT_CATEGORIES, EVENT_NAMES } from '../../services/ga-event-tracker';

const SHARE_URL = URLS.HTTP_ROOT;

interface Props extends WithLocalizationProps {
  shareTextId?: string;
  reverse?: boolean;
  color?: string;
}

function ShareButtons({ getString, shareTextId, reverse, color }: Props) {
  const [locale] = useLocale();
  const addNotification = useAction(Notifications.actions.addPill);
  const encodedShareText = encodeURIComponent(
    shareTextId
      ? getString(shareTextId, { link: SHARE_URL })
      : getString('share-text', { link: SHARE_URL })
  );
  const shareURLInputRef = useRef(null);

  const handleFacebookClick = () => {
    trackSharing('facebook', locale)
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.GO_TO_FACEBOOK,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Facebook link in the footer'
    );
  };

  const handleCopyClick = () => {
    shareURLInputRef.current.select();
    document.execCommand('copy');
    trackSharing('link', locale);

    addNotification(
      <>
        <FontIcon type="link" className="icon" />{' '}
        <Localized id="link-copied">
          <span />
        </Localized>
      </>
    );

    trackGoogleAnalyticsEvent(
      EVENT_NAMES.COPY_LINK,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Copy link in the footer'
    );
  };

  const handleTwitterClick = () => {
    trackSharing('twitter', locale)
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.GO_TO_TWITTER,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Twitter link in the footer'
    );
  };

  return (
    <>
      <a
        className="share-button"
        href={
          URLS.FACEBOOK +
          encodeURIComponent(SHARE_URL)
        }
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleFacebookClick}>
        <FacebookIcon reverse={reverse} color={color} />
      </a>
      <a
        className="share-button"
        href={URLS.TWITTER + encodedShareText}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleTwitterClick}>
        <TwitterIcon reverse={reverse} color={color} />
      </a>
      <button
        id="link-copy"
        className="share-button"
        onClick={handleCopyClick}>
        <input type="text" readOnly value={SHARE_URL} ref={shareURLInputRef} />
        <CopyLinkIcon color={color} />
      </button>
    </>
  );
}

export default withLocalization(ShareButtons);
