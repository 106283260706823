const { gtag } = require('ga-gtag');

export const trackGoogleAnalyticsEvent = (eventName: string, eventCategory: string, eventLabel: string) => {
  return gtag('event', eventName, {
    event_category: eventCategory,
    event_label: eventLabel
  });
};

export const EVENT_NAMES = {
  PROFILE_CREATED: 'profile_created',
  PROFILE_UPDATED: 'profile_updated',
  JOIN_MAILING_LIST: 'join_mailing_list',
  LEAVE_MAILING_LIST: 'leave_mailing_list',
  READ_FAQ: 'read_faq',
  READ_PRIVACY: 'read_privacy',
  READ_TERMS: 'read_terms',
  VIEW_MOZILLA: 'view_mozilla',
  VIEW_MOZILLA_GITHUB: 'view_mozilla_github',
  RERECORD_CLIP: 'rerecord_clip',
  REPLAY_CLIP: 'replay_clip',
  SHARE_RECORDING: 'share_recording',
  SKIP_SENTENCE: 'skip_sentence',
  GO_TO_RECORDING: 'go_to_recording',
  GO_TO_FACEBOOK: 'go_to_facebook',
  GO_TO_TWITTER: 'go_to_twitter',
  COPY_LINK: 'copy_link',
  CONTACT_US: 'contact_us',
  CREATE_ACCOUNT: 'create_account',
  START_RECORDING: 'start_recording',
  GO_TO_APP: 'go_to_app',
}

export const EVENT_CATEGORIES = {
  LINK_USAGE: 'link_usage',
  BUTTON_USAGE: 'button_usage',
  SHORTCUT_USAGE: 'shortcut_usage',
  CHECKBOX_USAGE: 'checkbox_usage'
}
