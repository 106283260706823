import * as React from 'react';
import { Localized } from '@fluent/react';

import URLS from '../../urls';
import ShareButtons from '../share-buttons/share-buttons';
import { TextButton } from '../ui/ui';
import Logo from './logo'
import { ContactLink } from '../shared/links';
import { trackGoogleAnalyticsEvent, EVENT_CATEGORIES, EVENT_NAMES } from '../../services/ga-event-tracker';

import './footer.css';

export default React.memo(() => {
  const handleFAQClick = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.READ_FAQ,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on FAQ link in the footer'
    );
  };

  const handlePrivacyClick = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.READ_PRIVACY,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Privacy link in the footer'
    );
  };

  const handleTermsClick = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.READ_TERMS,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Terms link in the footer'
    );
  };

  const handleMozillaLinkClick = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.VIEW_MOZILLA,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Mozilla link in the footer'
    );
  };

  const handleGithubLinkClick = () => {
    trackGoogleAnalyticsEvent(
      EVENT_NAMES.VIEW_MOZILLA_GITHUB,
      EVENT_CATEGORIES.LINK_USAGE,
      'Click on Github link in the footer'
    );
  };

  return (
    <footer>
      <div id="moz-links">
        <div className="logo-container">
          <Logo />
          <div className="license-container">
            <p className="license">
              <Localized
                id="content-license-text"
                elems={{
                  licenseLink: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.mozilla.org/en-US/foundation/licensing/website-content/"
                    />
                  ),
                  mcv: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://commonvoice.mozilla.org/en"
                      onClick={handleMozillaLinkClick}
                    />
                  ),
                  github: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/common-voice/common-voice"
                      onClick={handleGithubLinkClick}
                    />
                  ),
                }}>
                <span />
              </Localized>
            </p>
          </div>
        </div>
        <div className="links">
          <div>
            <a target="_blank" id="terms" href={URLS.FAQ} onClick={handleFAQClick}>
              FAQ
            </a>
            <ContactLink id="contact-link">Contact</ContactLink>
            <a target="_blank" id="privacy" href={URLS.PRIVACY} onClick={handlePrivacyClick}>
              Privacy
            </a>
            <a target="_blank" id="terms" href={URLS.TERMS} onClick={handleTermsClick}>
              Terms
            </a>
          </div>
        </div>

        <div id="sharing">
          <Localized id="join-our-community">
            <span className="title" />
          </Localized>

          <Localized id="make-an-impact">
            <span className="title" />
          </Localized>

          <div className="icons">
            <ShareButtons />
          </div>
        </div>

        <Localized id="back-top">
          <TextButton
            className="back-top"
            onClick={() => {
              document.getElementById('scroller').scrollTop = 0;
            }}
          />
        </Localized>
      </div>
    </footer>
  );
});
