export const DAILY_GOALS = Object.freeze({
  speak: [1200, 2500],
  listen: [2400],
});

export const BENEFITS = [
  'rich-data',
  'improve-audio',
  'keep-track',
  'compare-progress',
  'view-goals',
  'join-newsletter',
];

export const WHATS_PUBLIC = [
  'email-not-public',
  'recordings-and-locale-public',
  'username-optin-public',
  'demographic-deidentified-clarity',
  'username-email-not-demographic',
];

export const ENSEMBLE_ROLES = {
  ANNOTATOR: 'ANNOTATOR',
};

export const USAGE_TRACKING_ACTIONS = {
  HOME_SCHEDULE_AN_APPOINTMENT: 'HOME_SCHEDULE_AN_APPOINTMENT',
  SPEAK_KEY_RECORD_STOP: 'SPEAK_KEY_RECORD_STOP',
  SPEAK_KEY_RERECORD: 'SPEAK_KEY_RERECORD',
  SPEAK_KEY_SKIP: 'SPEAK_KEY_SKIP',
  SPEAK_BTN_SKIP: 'SPEAK_BTN_SKIP',
  SPEAK_BTN_REPLAY: 'SPEAK_BTN_REPLAY',
  SPEAK_BTN_RERECORD: 'SPEAK_BTN_RERECORD',
  SPEAK_BTN_SHARE: 'SPEAK_BTN_SHARE',
}

export type UsageTrackingActionId = typeof USAGE_TRACKING_ACTIONS[keyof typeof USAGE_TRACKING_ACTIONS]

export const GOOGLE_ANALYTICS_SITE_TAG = 'G-XD3QT1W3QT'
// my tracking id for testing purposes on CD env
export const GOOGLE_ANALYTICS_SITE_TAG_CD = 'G-S2GHCR4PT5'

export const SUPPORT_EMAIL = 'support@voiceitt.com'

export const SUPPORTED_BROWSERS = [
  {
    name: 'Chrome',
    min_version: '76.0.0.0'
  },
  {
    name: 'Safari',
    min_version: '12.0.0.0'
  },
  {
    name: 'Firefox',
    min_version: '72.0.0.0'
  },
  {
    name: 'Edge',
    min_version: '79.0.0.0'
  },
  {
    name: 'Mobile Safari',
    min_version: '12.0.0.0'
  }
]
